import { createIcon } from "@chakra-ui/icons";

export const WhatsappBrand = createIcon({
  displayName: "WhatsappBrand",
  viewBox: "0 0 512 512",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
    />
  ),
});

export const FacebookBrand = createIcon({
  displayName: "FacebookBrand",
  viewBox: "0 0 320 512",
  path: (
    <path
      fill="currentColor"
      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
    />
  ),
});

export const AppBrand = createIcon({
  displayName: "AppBrand",
  viewBox: "0 0 500 162",
  path: (
    <>
      <path
        d="M27.7462 127.778L0 30.8081H22.3958L38.4469 98.185H39.2519L56.96 30.8081H76.1361L93.7972 98.3273H94.6494L110.701 30.8081H133.097L105.351 127.778H85.3694L66.9033 64.3784H66.1461L47.7273 127.778H27.7462Z"
        fill="white"
      />
      <path
        d="M250.363 127.778V30.8081H288.621C295.976 30.8081 302.241 32.2128 307.418 35.0222C312.594 37.7999 316.541 41.6667 319.255 46.6225C322.001 51.5468 323.374 57.2284 323.374 63.6678C323.374 70.1073 321.986 75.7889 319.208 80.7134C316.43 85.6378 312.406 89.4728 307.134 92.2189C301.894 94.9656 295.549 96.3384 288.1 96.3384H263.716V79.9084H284.786C288.731 79.9084 291.982 79.23 294.539 77.8723C297.128 76.4834 299.053 74.5739 300.316 72.1434C301.61 69.6812 302.257 66.8562 302.257 63.6678C302.257 60.4484 301.61 57.6389 300.316 55.2399C299.053 52.8094 297.128 50.9312 294.539 49.6055C291.951 48.2482 288.668 47.5695 284.691 47.5695H270.865V127.778H250.363Z"
        fill="white"
      />
      <path
        d="M336.691 127.778V30.8081H402.032V47.7115H357.193V70.8178H398.671V87.7212H357.193V110.874H402.222V127.778H336.691Z"
        fill="white"
      />
      <path
        d="M499.439 30.8081V127.778H481.731L439.544 66.7456H438.834V127.778H418.332V30.8081H436.324L478.18 91.7928H479.033V30.8081H499.439Z"
        fill="white"
      />
      <path
        d="M166.539 116.205L162.517 113.855L149.183 117.494L152.811 104.156L150.467 100.133C146.539 93.4055 144.444 85.7556 144.444 77.7778C144.444 68.9877 147.051 60.3945 151.935 53.0858C156.819 45.777 163.76 40.0805 171.881 36.7166C180.002 33.3527 188.938 32.4725 197.559 34.1874C206.181 35.9023 214.1 40.1352 220.316 46.3509C226.531 52.5665 230.764 60.4859 232.479 69.1068C234.194 77.7284 233.314 86.6648 229.95 94.7858C226.586 102.907 220.889 109.848 213.581 114.732C206.272 119.615 197.679 122.222 188.889 122.222C181.036 122.238 173.322 120.162 166.539 116.205Z"
        fill="#127294"
      />
      <path
        d="M165.154 110.248L168.774 112.363C174.878 115.923 181.822 117.792 188.889 117.778C196.8 117.778 204.534 115.432 211.112 111.037C217.689 106.641 222.817 100.394 225.844 93.085C228.872 85.7761 229.664 77.7334 228.121 69.9739C226.577 62.215 222.767 55.0876 217.173 49.4935C211.579 43.8994 204.452 40.0898 196.692 38.5464C188.933 37.003 180.891 37.7952 173.582 40.8227C166.273 43.8502 160.026 48.977 155.63 55.555C151.235 62.1328 148.889 69.8667 148.889 77.7778C148.889 84.9578 150.774 91.8428 154.309 97.8978L156.419 101.518L153.154 113.523L165.154 110.248ZM138.909 127.778L145.669 102.938C141.215 95.3022 138.875 86.6178 138.889 77.7778C138.889 50.1628 161.274 27.7778 188.889 27.7778C216.504 27.7778 238.889 50.1628 238.889 77.7778C238.889 105.393 216.504 127.778 188.889 127.778C180.053 127.792 171.372 125.453 163.739 121.003L138.909 127.778Z"
        fill="white"
      />
    </>
  ),
});
